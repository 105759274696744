import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import { Provider } from 'react-redux';
import App from './App';

import GlobalStyle from './styles/globalStyles';

// if react 18 and above
// const widgetDivs = document.querySelectorAll('.heytrust-vid-widget');

// widgetDivs.forEach((elem) => {
//   const data = Object.assign({}, elem.dataset);
//   ReactDOM.createRoot(elem).render(
//   <React.StrictMode><App resource={data} /></React.StrictMode>
//   )
// });


// Find all widget divs
const widgetDivs = document.querySelectorAll('.fyynvideo');

// Inject our React App into each class
widgetDivs.forEach(div => {
  // const data = Object.assign({}, div.dataset);
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <App sessid={div.dataset.sessid}/>
        </Provider>
        <GlobalStyle />
      </React.StrictMode>,
      div
    );
});


// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//     <GlobalStyle />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
