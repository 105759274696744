import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import OpenSans400 from './../assets/fonts/OpenSans400.woff2';
import OpenSans700 from './../assets/fonts/OpenSans700.woff2';

const GlobalStyle = createGlobalStyle`
  :root {
    --white: #fff;
    --muted: ${theme.muted};
    --primary: ${theme.primary};
    --dark: ${theme.dark};
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${OpenSans400}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${OpenSans700}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #fff;
    ${'' /* min-height: 100vh; */}
    overflow-x: hidden;
  }

  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }

  hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
  }

  hr:not([size]) {
    height: 1px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
  }

  h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  @media (min-width: 1200px) {
    h1 {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: calc(1.325rem + 0.9vw);
  }

  @media (min-width: 1200px) {
    h2 {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }

  @media (min-width: 1200px) {
    h3 {
      font-size: 1.75rem;
    }
  }

  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }

  @media (min-width: 1200px) {
    h4 {
      font-size: 1.5rem;
    }
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul {
    padding-left: 2rem;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 0.875em;
  }

  mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }

  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  a {
    color: var(--brand-primary);
    text-decoration: underline;
  }

  a:hover {

  }

  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
  }

  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
    -ms-overflow-style: scrollbar;
  }

  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }

  code {
    font-size: 0.875em;
    color: #d63384;
    word-wrap: break-word;
  }

  a > code {
    color: inherit;
  }

  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.875em;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
  }

  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700;
  }

  figure {
    margin: 0 0 1rem;
  }

  img,
  svg {
    vertical-align: middle;
  }

  table {
    caption-side: bottom;
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: left;
  }

  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  label {
    display: inline-block;
  }

  button {
    border-radius: 0;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  [role="button"] {
    cursor: pointer;
  }

  select {
    word-wrap: normal;
  }

  [list]::-webkit-calendar-picker-indicator {
    display: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer;
  }

  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  textarea {
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
    white-space: normal;
  }

  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem;
    }
  }

  legend + * {
    clear: left;
  }

  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }

  ::-webkit-inner-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  iframe {
    border: 0;
  }

  summary {
    display: list-item;
    cursor: pointer;
  }

  progress {
    vertical-align: baseline;
  }

  [hidden] {
    display: none !important;
  }

  .table {
    width: 100%;
    color: #212529;
    margin-bottom: 20px;
  }

  table {
    border-collapse: collapse;
  }

  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    font-size: 14px;
    letter-spacing: -.5px;
    font-weight: 500;
  }

  .table td,
  .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  .main {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 60px);
  }

  .sa-btn {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    line-height: 1.5;
    border-radius: 3px;
    text-decoration: none;
    border: 1px solid var(--brand-primary);
    padding: 11px 18px;
    font-weight: bold;
    font-size: 16px;
    color: var(--brand-text);
    transition: all 300ms ease 0ms;
    background-color: var(--brand-primary);
  }

  .sa-btn-secondary {
    border: 1px solid var(--muted);
    background-color: var(--muted);
  }

  .sa-btn-block {
    display: block;
    width: 100%;
  }

  .sa-btn-sm {
    padding: 9px 18px;
    font-weight: bold;
    font-size: 14px;
  }

  .sa-btn.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  button,
  input {
    overflow: visible;
  }

  .button-group {
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    background-color: white;
    box-shadow: 0px -4px 10px rgba(75, 74, 74, 0.07), 0px 4px 10px rgba(75, 74, 74, 0.07);
  }

  .button-group .sa-btn {
    min-width: 120px;
  }

  .danger-alert-text {
    font-size: 14px;
    color: #F25544;
  }

  .required-color {
    color: #F25544;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #C6D0C3;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out;

    &:focus {
      outline: none;
      border-color: #80af71;
    }
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }
  textarea.form-control {
    height: auto;
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }

  input[type=checkbox],
  input[type=radio] {
    box-sizing: border-box;
    padding: 0;
  }
  .form-check-input {
      position: absolute;
      margin-top: .3rem;
      margin-left: -1.25rem;
  }
  .form-check-label {
    margin-bottom: 0;
}

  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat;
    border: 1px solid #C6D0C3;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      outline: none;
      border-color: #80af71;
    }
  }

  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 0;
  }

  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0;
  }

  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #C6D0C3;
    border-radius: .25rem;
    margin: 0;
  }

  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: -.25rem;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.4;
    color: white;
    content: "Browse";
    background-color: #80af71;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
    border: 1px solid #80af71;
  }

  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .embed-responsive::before {
    display: block;
    content: "";
  }

  .fn__video {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    background-color: #AFBDC0;

    @media ( min-width: 577px) {
      max-height: 530px;
      overflow: hidden;
    }
  }
  .fn__video__01 {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    background-color: #AFBDC0;
  }

  .mobile-flip {
    @media (max-width: 576px) {
      -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
  }

  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }

  .embed-responsive-4by3::before {
    padding-top: 75%;
  }

  .embed-3by4::before {
    padding-top: 133%;
  }

  .embed-responsive .embed-responsive-item,
  .embed-responsive embed,
  .embed-responsive iframe,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: #AFBDC0;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    transition: opacity .15s linear;
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .d-flex {
    display: flex;
  }

  .d-inline-flex {
    display: inline-flex;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .justify-content-start {
    justify-content: flex-start;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-around {
    justify-content: space-around;
  }

  .align-items-start {
    align-items: flex-start;
  }

  .align-items-end {
    align-items: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .align-items-baseline {
    align-items: baseline;
  }

  .align-items-stretch {
    align-items: stretch;
  }

  .img-hidden {
    visibility: hidden;
  }

  .toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none;
  }

  .toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: .25rem;
  }

  .toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
  }

  .toast-body {
    padding: .75rem;
    word-wrap: break-word;
  }

  button:focus {
    box-shadow: none;
    outline: none;
  }

`;

export default GlobalStyle;
