import React, { useEffect, useState } from 'react';
// import { nanoid } from '@reduxjs/toolkit';
import { MemoryRouter, BrowserRouter, Route, useHistory } from "react-router-dom";
import axios from 'axios'
import { useDispatch } from 'react-redux';
import fireUtils from './firebase/firebaseUtils';

// screens
import Steps from './screens/Steps';
import Alert from './alert/Alert';

import { loadAppData } from './services/slices/rootSlice';

function Welcome() {
  const history = useHistory();

  return (
    <div
      style={{
        minHeight: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <button
        className={`sa-btn sa-btn-block`}
        style={{
          display: 'inline-block',
          width: 'auto',
          color: 'white',
          padding: '5px 17px',
          fontWeight: 'normal',
          backgroundColor: 'indigo',
          border: '1px solid indigo',
        }}
        onClick={() => history.push('/start')}
      >
        Let's Start
      </button>
    </div>
  )
}

function App({ sessid }) {
  const dispatch = useDispatch();
  const [intialFetch, setIntialFetch] = useState({});

  console.log(' data-sessid =>', sessid)
  // const { mediaAlert } = useSelector(state => state.root);
  // const info = mediaAlert && mediaAlert.info;
  // const notSupported = mediaAlert && mediaAlert.notSupported;
  // const notAllowed = mediaAlert && mediaAlert.notAllowed;
  // const combined = Object.assign({ info, notSupported, notAllowed });


  const { createDocumentWithID } = fireUtils;

  useEffect(() => {

    const codec = 2;
    async function fetchData() {
      // const result = await axios('https://fyyn.io/startRecord.php');
      const result = await axios(`https://fyyn.io/startRecord.php?sessId=${sessid}`);
      const { sessionId, userId, userName } = result.data;
      setIntialFetch(result.data)
      console.log(result.data)
      return { sessionId, userId, userName, codec }
    }
    fetchData().then((res) => {
      createDocumentWithID('sessionIdCollection', res, res.sessionId)
    });
  }, [createDocumentWithID, sessid]);

  useEffect(() => {
    dispatch(loadAppData(`${intialFetch.sessionId}`));
  }, [dispatch, intialFetch.sessionId]);

  return (
    <>
      <BrowserRouter>
        <main>
          <MemoryRouter>
            <Route exact path="/" component={Welcome} />
            <Route exact path="/start">
              <Steps sessionId={intialFetch.sessionId} />
            </Route>
          </MemoryRouter>
        </main>
        <Alert />
      </BrowserRouter>
    </>
  );
}

export default App;
