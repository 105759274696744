import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

// https://codepen.io/amit_sheen/pen/oKaabp

export default function CountDown ({ timer = 0 }) {
  return (
    <Canvas>
      <Content>
        <Spinner />
        <div style={{ position: 'relative', zIndex: 100 }}>{timer}</div>
      </Content>
    </Canvas>
  )
}


const Canvas = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #777;
  color: white;
  font-size: 70px;
  line-height: normal;
  font-weight: bold;
  border-radius: 5px;
  background-image: linear-gradient(black, transparent 10%, transparent calc(50% - 1px), #aaa calc(50% - 1px), #aaa calc(50% + 1px), transparent calc(50% + 1px), transparent 90%, black),
    linear-gradient(90deg, black, transparent 10%, transparent calc(50% - 1px), #aaa calc(50% - 1px), #aaa calc(50% + 1px), transparent calc(50% + 1px), transparent 90%, black);
`;

const Content = styled.div`
  width: 250px;
  height: 250px;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #aaa;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black ;

  &::after {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    top: 23px;
    left: 23px;
    border: 1px solid #aaa;
    border-radius: 50%;
    background: #777;
    background-image:
      linear-gradient(transparent calc(50% - 1px), #aaa calc(50% - 1px), #aaa calc(50% + 1px), transparent calc(50% + 1px)),
      linear-gradient(90deg, transparent calc(50% - 1px), #aaa calc(50% - 1px), #aaa calc(50% + 1px), transparent calc(50% + 1px));
  }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
`

const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 125px;
  height: 100px;
  transform-origin: 0 0;
  background-image: linear-gradient(black, transparent);
  animation: ${spin} 1s infinite linear;
`;
