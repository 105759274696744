import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import rootReducer from './services/slices/rootSlice';
import testReducer from './services/slices/testSlice';
import alertReducer from './services/slices/alertSlice';

const reducer = {
  root: rootReducer,
  videoTest: testReducer,
  notifications: alertReducer,
}

const store = configureStore({
  reducer,
  middleware: [...getDefaultMiddleware()]
})

export default store;
