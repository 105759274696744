import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';

const app = firebase.initializeApp({
  apiKey: "AIzaSyBeQhQFoOJVWjh3vXQtXCHGbgbMONTBtN0",
  authDomain: "slashvid-54e73.firebaseapp.com",
  projectId: "slashvid-54e73",
  storageBucket: "slashvid-54e73.appspot.com",
  messagingSenderId: "91172557544",
  appId: "1:91172557544:web:67d27f464539822fe8ef5d"
});


export const db = getFirestore(app);

export default app;
