import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './config';

// https://firebase.google.com/docs/firestore/security/rules-conditions

const createDocumentWithID = async (collectionName, data, id) => {
  const userRef = doc(db, collectionName, id);
  await setDoc(userRef, data, { merge: true });
}

const readDocumentWithID = async (collectionName, id) => {
  const docRef = doc(db, collectionName, id);
  const docSnapshot = await getDoc(docRef);

  if(docSnapshot.exists()) {
    return docSnapshot.data();
  } else {
    return new Error('No data available')
  }
}


const fireUtils = {
  createDocumentWithID,
  readDocumentWithID
};

export default fireUtils;
